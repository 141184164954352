import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CookieSettingsComponent from "../components/cookie-settings-component"
const CookieSettings = () => (
    <Layout showCookieConsent={false} showCookieSettingsLink={false}>
        <SEO title="Cookie settings" />
        <div className={"container"}>
            <div className={"content"}>
                <div className={"title"}>
                    <h1>Cookie settings</h1>
                </div>
                <CookieSettingsComponent showCookieSettingsLink={false} />
            </div>
        </div>
    </Layout>
)

export default CookieSettings
